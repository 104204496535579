<template>
  <!-- 兑换码设置 -->
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <router-link tag="a" to="/admin/PromotionManage/exchangeManage">兑换码设置</router-link>
         / 码库详情
      </template>
    </HeaderBox>

    <div class="product-detail">
      <div class="item"><span>兑换码名称：</span>{{detail.batchName}}</div>
      <div class="item"><span>价值：</span>￥{{detail.payPrice? detail.payPrice.toFixed(2): '-'}}</div>
      <div class="foot">
        <div class="item" style="margin-top:20px"><span>总库存：</span>{{detail.totalNum}}</div>
        <div class="item" style="margin-top:20px"><span>领取人数：</span>{{detail.getNum}}</div>
        <div class="item" style="margin-top:20px"><span>核销人数：</span>{{detail.redeemNum}}</div>
      </div>
    </div>

    <div class="btn-box">
      <div>
        <a-input class="btn" v-model="queryParams.codeValue" placeholder="请输入码值" allowClear />
        <a-input class="btn" v-model="queryParams.mobile" placeholder="请输入核销手机号" allowClear />
        <a-button type="primary" @click="onSearch" :loading="loading" class="btn">搜索</a-button>
      </div>
      <div>
        <a-button type="primary" class="btn" @click="onExport">导出码库</a-button>
        <a-upload
          :disabled="uploadLoading"
          name="videoFileId"
          :showUploadList="false"
          :customRequest="customRequest"
        >
          <template>
            <a-button :loading="uploadLoading" type="primary" class="btn" >{{uploadLoading?'导入中...':'导入领取情况'}}</a-button>
          </template>
        </a-upload>
      </div>
    </div>

    <a-table 
        class="table-template"
        :rowKey="(item,index)=>index"
        :columns="columns" 
        :loading="loading"
        :data-source="tableData" 
        :locale="{emptyText: '暂无数据'}" 
        @change="onPage"
        :pagination="{
          total:total,
          current:queryParams.pageNum,  
          defaultpageSize:queryParams.pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
            return `共${total}条`
          }
        }">
        <!-- 序号 -->
      <template
        slot="index"
        slot-scope="item, row, index"
      >
        {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }}
      </template>

      <!-- 状态 -->
      <template slot="codeStatus" slot-scope="row">
        <span 
          class="state_all" 
          :class="row == 5
            ? 'state_color_green'
            : row == 3
            ? 'state_color_yellow'
            : row == 7
            ? 'state_color_gray'
            : ''
          ">
          {{row == 1 ? '已生成' : row == 3 ? '已领取' : row == 5 ? '已兑换' : row == 7 ? '已作废' : ''}}
        </span>
      </template>

      <!-- 操作 -->
      <template slot="operation" slot-scope="item,row">
        <span class="blueText">
          <span v-if="row.codeStatus == 1 || row.codeStatus == 3" @click="deleteData(row)">作废</span>
          <span v-else>-</span>
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "码值",
    align: "center",
    dataIndex: "codeValue",
  },
  {
    title: "手机号（外）",
    align: "center",
    dataIndex: "buyerMobile",
  },
  {
    title: "微信昵称（外）",
    align: "center",
    dataIndex: "buyer",
  },
  {
    title: "购买时间（外）",
    align: "center",
    dataIndex: "buyPayTime",
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "userName",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "核销时间",
    align: "center",
    dataIndex: "payTime",
  },
  {
    title: "关联订单",
    align: "center",
    dataIndex: "orderCode",
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "codeStatus",
    scopedSlots: {
      customRender: "codeStatus",
    },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
export default {
  // 可用组件的哈希表
  components: {HeaderBox},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns,
      loading:false,
      tableData:[{}],
      total:0,
      queryParams: {
        batchId:0,
        codeValue:'',
        mobile:'',
        pageNum: 1, //页码
        pageSize: 10, // 页数
      },
      uploadLoading:false,
      detail:{},
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.pageNum = e.current
      this.queryParams.pageSize = e.pageSize
      this.getList()
    },

    onSearch() {
      this.queryParams.pageNum = 1;
      this.getList();
    },

    // 查询兑换券列表
    getList(e) {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/redeem/recordList",
        params: this.queryParams,
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },

    // 获取详情
    getDetail(){
      this.$ajax({
        url: '/hxclass-management/redeem/getDetail',
        params: {
          id: this.queryParams.batchId,
        }
      }).then(res=>{
        if (res.code == 200 && res.success) {
          this.detail = res.data
        }
      })
    },

    // 作废兑换券
    deleteData(item) {
      let _this = this;
      this.$confirm({
        title: "作废后将不可再使用，是否确认?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/redeem/abrogate/" + item.id,
              method: "PUT",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("作废成功");
                _this.getList();
              }
            });
        },
        onCancel() {},
      });
    },

    // 导出码库
    onExport() {
      window.open(
        this.$config.target + 
        "/hxclass-management/redeem/export?batchId=" +
        encodeURIComponent(this.queryParams.batchId || '') +
        "&batchName=" +
        encodeURIComponent(this.detail.batchName || '')
      );
    },

    // 导入
    customRequest(e){
      let params = {
        file: e.file,
      }
      this.uploadLoading = true
      this.$ajax({
        url: "/hxclass-management/redeem/import/" + this.queryParams.batchId,
        headers: [{ type: "file" }],
        method: "post",
        params: params,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("导入成功");
          this.getList();
        }
        this.uploadLoading = false
      });
    },

    // 页面跳转
    toDetail(type){
      if (type == 1){
        this.$router.push(
          "/admin/PromotionManage/exchangeAdd"
        );
      } else if (type == 2){
        this.$router.push(
          "/admin/PromotionManage/codeDetail"
        );
      }
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.queryParams.batchId = this.$route.query.batchId;
    this.getList()
    this.getDetail()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}

.product-detail{
  margin-top: 20px;
  border-radius: 5px;
  padding: 20px;
  background: #f9f9f9;
  .item{
    display: inline-block;
    width: calc(100% / 3);
    color: #333333;
  }
  .foot{
    margin-top: 20px;
  }
}

.btn-box{
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  >div{
    display: flex;
  }
}
.state_color_gray {
  color: #999;
  &::before {
    background: #999;
  }
}
</style>
